.About {
    margin: 0;
    flex: 1;
    margin: 0 .75rem;
    display: flex;
}

.About-flex {
    display: flex;
    flex-direction: column;
}

.About-flexGrow {
    flex-grow: 1;
    min-width: 2rem;
}

.About-p {
    font-size: 1rem;
}

.About-section {
    padding-bottom: 1rem;
}

@media screen and (max-width: 768px) {


  .About-p {
    font-size: .8rem;

}
}
    
