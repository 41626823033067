.Contact {
    margin: 0;
    flex: 1;
    margin: 0 .75rem;
    display: flex;
}

.Contact-flex {
    display: flex;
    flex-direction: column;
}

.Contact-flexGrow {
    flex-grow: 1;

}