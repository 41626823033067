.OneClickGallery {
    margin: 0;
    flex: 1;
    /* max portraits fit */
    overflow: hidden;
    margin: 0 .75rem;
    position: relative;
}

.OneClickGallery-navigation {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
}

.OneClickGallery-navigation-back {
    flex: 1;
}
.OneClickGallery-navigation-forward {
    flex: 1;
}

.OneClickGallery-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
}