/* reset */

body, h1, h2, h3, h4, h5, h6, p, ol, ul, li, dl, dt, dd, blockquote, figure, pre, hr, fieldset, legend, textarea, th, td, input, button {
  margin: 0;
  padding: 0;
}

body {
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {

  body {
    background: #000;
    color: #fff;
  }
}

/* fonts */

body {
  font-family: 'BRRR Skrrt', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 100;
  margin-bottom: .2em;
}

p, h2, h3, h4 {
  margin: 0.4em 0;
  font-size: 1.2rem;
  font-weight: normal
}

h1 {
  font-size: 2.4rem;
  font-weight: normal;
}

.brrr {
  font-family: 'BRRR Skrrt';

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* links */
a {
  cursor: pointer;
  display: inline;
}

button {
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}

button:hover {
  text-decoration: inherit;
}

p {
  max-width: 760px;
}

