.App {
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-height: 1440px;
    top: 50%;
    transform: translateY(-50%);
}
